<template>
  <div class="menu">
    <left-tabs :tabList="leftList" />
    <router-view></router-view>
  </div>
</template>

<script>
import LeftTabs from "@/components/leftTabs";
export default {
  components: {LeftTabs},
  name: "menuIndex",
  data() {
    return {
      leftList: [
        { id: 1, name: "菜单列表", url: "/jurisdiction/admin" },
      ],
    };
  },
  methods: {},
};
</script>

<style scoped lang='less'>
.menu {
  display: flex;
}
</style>